<template>
  <Card class="main-card--up">
    <template #title>Страница не найдена</template>
  </Card>
</template>

<script>
import Card from '@/components/Card';

export default {
  name: 'NotFound',
  components: { Card },
};
</script>

<style lang="scss"></style>
