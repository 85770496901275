import axios from 'axios';

const API_URL = '/api/v1/';
const userInitial = process.env.NODE_ENV === 'production'
  ? $cookies.isKey('user')
    ? $cookies.get('user')
    : null
  : JSON.parse(localStorage.getItem('user'));

const state = {
  logged: !!userInitial,
  userId: userInitial?.acc ? userInitial.acc : null,
  userParams: {},
  userPayments: [],
  userCounters: [],
  periods: [],
  currentPeriod: null,
  loading: false,
  register: false,
  error: false,
};

const actions = {
  async userLogin({ commit }, { acc, password }) {
    commit('setLoading', true);
    commit('setError', false);
    try {
      await axios.post(`${API_URL}auth/signin`, { acc, password }).then((res) => {
        if (res.data.token) {
          localStorage.setItem('user', JSON.stringify(res.data));
          $cookies.set('user', res.data, null, null, 'ukpremium.ru');
        }
        return res.data;
      });
      commit('setLogged', true);
      commit('setUserId', acc);
    } catch (e) {
      console.log(e);
      commit('setError', true);
    }
    commit('setLoading', false);
  },

  userLogout: ({ commit }) => {
    commit('setLoading', true);
    localStorage.removeItem('user');
    $cookies.remove('user');
    commit('setLogged', false);
    commit('setUserId', null);
    commit('setuserParams', {});
    commit('setUserPayments', []);
    commit('setUserCounters', []);
    commit('setLoading', false);
  },

  async getUserData({ commit, dispatch }) {
    commit('setLoading', true);
    const user = process.env.NODE_ENV === 'production'
      ? $cookies.get('user')
      : JSON.parse(localStorage.getItem('user'));
    const { acc } = user;
    const headers = user?.token ? { 'x-access-token': user.token } : {};
    try {
      await axios.get(`${API_URL}user`, { headers, params: { acc } }).then((res) => {
        commit('setuserParams', res.data);
      });
    } catch (e) {
      console.log(e);
      if (e.code === 'ERR_BAD_REQUEST') {
        dispatch('userLogout');
      }
    } finally {
      commit('setLoading', false);
    }
  },

  async getUserPayments({ commit, dispatch }) {
    commit('setLoading', true);
    const user = process.env.NODE_ENV === 'production'
      ? $cookies.get('user')
      : JSON.parse(localStorage.getItem('user'));
    const { acc } = user;
    const headers = user?.token ? { 'x-access-token': user.token } : {};
    try {
      await axios.get(`${API_URL}payments`, { headers, params: { acc } }).then((res) => {
        commit('setUserPayments', res.data);
      });
    } catch (e) {
      console.log(e);
      if (e.code === 'ERR_BAD_REQUEST') {
        dispatch('userLogout');
      }
    } finally {
      commit('setLoading', false);
    }
  },

  async getUserCounters({ commit, state, dispatch }) {
    commit('setLoading', true);
    const user = process.env.NODE_ENV === 'production'
      ? $cookies.get('user')
      : JSON.parse(localStorage.getItem('user'));
    const { acc } = user;
    if (!state.periods.lenght) {
      await dispatch('getPeriods');
    }
    const period = state.periods[0].PERIOD_ID;
    commit('setCurrentPeriod', period);
    const headers = user?.token ? { 'x-access-token': user.token } : {};
    try {
      await axios.get(`${API_URL}counters`, { headers, params: { acc, period } }).then((res) => {
        commit('setUserCounters', res.data);
      });
    } catch (e) {
      console.log(e);
      if (e.code === 'ERR_BAD_REQUEST') {
        dispatch('userLogout');
      }
    } finally {
      commit('setLoading', false);
    }
  },

  async getPeriods({ commit, dispatch }) {
    commit('setLoading', true);
    const user = process.env.NODE_ENV === 'production'
      ? $cookies.get('user')
      : JSON.parse(localStorage.getItem('user'));
    const { acc } = user;
    const headers = user?.token ? { 'x-access-token': user.token } : {};
    try {
      await axios.get(`${API_URL}receipts`, { headers, params: { acc } }).then((res) => {
        commit('setPeriods', res.data);
      });
    } catch (e) {
      console.log(e);
      if (e.code === 'ERR_BAD_REQUEST') {
        dispatch('userLogout');
      }
    } finally {
      commit('setLoading', false);
    }
  },
};

const getters = {};

const mutations = {
  setLoading: (state, payload) => {
    state.loading = payload;
  },
  setUserId: (state, payload) => {
    state.userId = payload;
  },
  setLogged: (state, payload) => {
    state.logged = payload;
  },
  setRegister: (state, payload) => {
    state.register = payload;
  },
  setuserParams: (state, payload) => {
    state.userParams = payload;
  },
  setUserPayments: (state, payload) => {
    state.userPayments = payload;
  },
  setUserCounters: (state, payload) => {
    state.userCounters = payload;
  },
  setPeriods: (state, payload) => {
    state.periods = payload;
  },
  setCurrentPeriod: (state, payload) => {
    state.currentPeriod = payload;
  },
  setError: (state, payload) => {
    state.error = payload;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
