import axios from 'axios';

const API_URL = '/api/v1/';
const userInitial = process.env.NODE_ENV === 'production'
  ? $cookies.isKey('user')
    ? $cookies.get('user')
    : null
  : JSON.parse(localStorage.getItem('user'));

const state = {
  loading: false,
  paymentLink: '',
  paymentState: '',
};

const actions = {
  async getLink({ commit, dispatch }, { amount, email }) {
    commit('setLoading', true);
    const user = process.env.NODE_ENV === 'production'
      ? $cookies.get('user')
      : JSON.parse(localStorage.getItem('user'));
    const { acc } = user;
    const headers = user?.token ? { 'x-access-token': user.token } : {};
    try {
      await axios.get(`${API_URL}payment-link`, { headers, params: { amount, email } }).then((res) => {
        commit('setLink', res.data?.formUrl);
      });
    } catch (e) {
      console.log(e);
      if (e.code === 'ERR_BAD_REQUEST') {
        dispatch('User/userLogout', {}, { root:true });
      }
    } finally {
      commit('setLoading', false);
    }
  },

  async checkPayment({ commit, dispatch }, { orderId }) {
    commit('setLoading', true);
    const user = process.env.NODE_ENV === 'production'
      ? $cookies.get('user')
      : JSON.parse(localStorage.getItem('user'));
    const { acc } = user;
    const headers = user?.token ? { 'x-access-token': user.token } : {};
    try {
      await axios.get(`${API_URL}payment-state`, { headers, params: { orderId } }).then((res) => {
        commit('setState', res.data);
      });
    } catch (e) {
      console.log(e);
      if (e.code === 'ERR_BAD_REQUEST') {
        dispatch('User/userLogout', {}, { root:true });
      }
    } finally {
      commit('setLoading', false);
    }
  },

  flushLink({ commit }) {
    commit('setLink', '');
  },
};

const getters = {};

const mutations = {
  setLoading: (state, payload) => {
    state.loading = payload;
  },
  setLink: (state, payload) => {
    state.paymentLink = payload;
  },
  setState: (state, payload) => {
    state.paymentState = payload;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
