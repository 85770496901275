<script>
export default {
  name: 'Button',
  props: {
    href: {
      default: '',
    },
    to: {
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <router-link :to="to" class="btn" :class="{ disabled }" v-if="to">
    <slot></slot>
  </router-link>
  <a
    :href="href"
    :target="/^http/.test(href) ? '_blank' : '_self'"
    class="btn"
    :class="{ disabled }"
    v-else-if="href"
  >
    <slot></slot>
  </a>
  <button
    :disabled="disabled"
    class="btn"
    :class="{ 'btn--loading': loading }"
    @click="click"
    v-else
  >
    <slot v-if="!loading"></slot>
    <img src="@/assets/img/loader.svg" v-if="loading" />
  </button>
</template>

<style lang="scss">
.btn,
a.btn {
  width: fit-content;
  border-radius: 5rem;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  color: $main-font-color;
  text-decoration: none;
  font-weight: 500;
  filter: $filter;
  min-width: 16rem;
  cursor: pointer;
  appearance: none;
  border: 1px solid transparent;
  background: #fff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &-white {
    background: #fff;

    &:hover {
      background: rgba(255, 255, 255, 0.7);
    }
  }

  &-transparent {
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    filter: none;

    &:hover {
      background: #fff;
      color: $darkgreen-font-color;
      filter: none;
    }
  }

  &-primary {
    background: $darkgreen-font-color;
    color: #fff;

    &:hover {
      background: #24657a;
    }
  }

  &-gradient {
    background: linear-gradient(to right, rgb(180, 206, 84), rgb(46, 122, 147));
    color: #fff;
    padding: 0.8rem 2rem;
    border: none;

    &:hover {
      background: linear-gradient(
        to right,
        rgba(180, 206, 84, 0.85) 0%,
        rgba(46, 122, 147, 0.85) 100%
      );
      color: #fff;
    }
  }

  &--loading {
    img {
      height: 1em;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
</style>
