<template>
  <Card class="main-card--up" :class="{loading: isSprintLoading}">
    <template #title
      >{{ name }}
      <div class="subline">Собственник</div></template
    >
    <Infoblock :text="address" />
    <Infoblock
      text="Электронная почта"
      :data="email"
      v-if="email"
    />
    <Infoblock
      :text="`Баланс лицевого счета ${userId}`"
      :data="balance"
      :payment="showPaymentLink"
    />
    <div v-if="isPayable" class="infoblock infoblock__payment">
      <div class="main-form">
        <InputComponent
          v-model.number="amount"
          :value="paymentAmount"
          type="number"/>
      </div>
      <div>
        <div>
          <Button
            @click="getPaymentLink"
            :disabled="!amount"
            class="btn-primary"
            :loading="loading"
            v-if="!link">
            Оплатить картой
          </Button>
          <Button
            :href="link"
            class="btn-gradient"
            @click="flushLink"
            v-else>
            Перейти к оплате
          </Button>
        </div>
      </div>
    </div>
    <div v-else class="infoblock">
      <div v-if="userCounters.length">
        Принимается оплата только за содержание.
        Возможно, вы вошли под учетной записью для учета взносов на капитальный ремонт.
      </div>
      <div></div>
    </div>
    <Button @click="logout">Выйти</Button>
  </Card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Card from '@/components/Card';
import Button from '@/components/Button';
import Infoblock from '@/components/Infoblock';
import InputComponent from '@/components/InputComponent';

export default {
  name: 'Home',
  components: {
    Card,
    Button,
    Infoblock,
    InputComponent,
  },
  data: () => ({
    amount: 0,
  }),
  async mounted() {
    if (!Object.keys(this.userParams).length) await this.getUserData();
    this.amount = Math.abs(Number(this.userParams?.cur_balance));
    if (!Object.keys(this.userCounters).length) this.getUserCounters();
  },
  computed: {
    ...mapState({
      userParams: (state) => state.User.userParams,
      userId: (state) => state.User.userId,
      link: (state) => state.Sber.paymentLink,
      loading: (state) => state.Sber.loading,
      isSprintLoading: (state) => state.User.loading,
      userCounters: (state) => state.User.userCounters,
    }),
    acc() {
      const user = process.env.NODE_ENV === 'production'
        ? this.$cookies.get('user')
        : JSON.parse(localStorage.getItem('user'));
      const { acc } = user;
      return acc;
    },
    email() {
      return this.userParams?.email || '';
    },
    name() {
      return this.userParams.name_kvartir
        ? this.userParams.name_kvartir.replace('Собственник: ', '')
        : '';
    },
    address() {
      return this.userParams?.address;
    },
    balance() {
      return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(
        this.userParams?.cur_balance,
      );
    },
    showPaymentLink() {
      return Number(this.userParams?.cur_balance) < 0 && this.isPayable;
    },
    paymentAmount() {
      return Math.abs(this.amount);
    },
    isPayable() {
      return this.userCounters.length ? this.userCounters.length > 2 : false;
    },
  },
  methods: {
    ...mapActions({
      logout: 'User/userLogout',
      getUserData: 'User/getUserData',
      getLink: 'Sber/getLink',
      flushLink: 'Sber/flushLink',
      getUserCounters: 'User/getUserCounters',
    }),
    getPaymentLink() {
      this.getLink({ amount: this.paymentAmount, email: this.email });
    },
  },
};
</script>

<style lang="scss">
.btn__logout {
  margin-top: 4rem;
}
.infoblock__payment > div:last-child {
  align-self: center;

  .btn-gradient {
    padding: 1rem 2rem;
  }
}
</style>
