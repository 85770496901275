<script>
import { mapState } from 'vuex';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Nav from '@/components/Nav';
import Login from '@/components/Login';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Nav,
    Login,
  },
  computed: {
    ...mapState({
      logged: (state) => state.User.logged,
    }),
  },
};
</script>

<template>
  <Header class="main-layout__header" />
  <section class="main-layout__content">
    <div class="container">
      <div class="row">
        <div class="sidebar">
          <Nav />
        </div>
        <div class="main">
          <Login v-if="!logged" />
          <router-view v-else />
        </div>
      </div>
    </div>
  </section>
  <Footer class="main-layout__footer" />
</template>

<style lang="scss"></style>
