<template>
  <section class="gradient">
    <div class="container">
      <div class="subheader">
        <div class="subheader__half subheader__text">
          <div>
            <ul class="breadcrumbs">
              <li><a href="/">Главная</a></li>
              <li>Личный кабинет</li>
            </ul>
            <div class="page-header">
              <h1>Личный кабинет</h1>
            </div>
          </div>
          <div class="gradient__filter-wrapper">
            <p class="gradient__filter gradient__filter--active" v-if="logged">
              № лицевого счета — {{ id }}
            </p>
          </div>
        </div>
        <div class="subheader__half subheader__img">
          <img
            src="../assets/img/girl-msg.png"
            alt="img"
            class="subheader__msg"
            v-if="displayMsg"
          />
          <img :src="imgSrc" alt="img" class="subheader__girl" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Subheader',
  computed: {
    ...mapState({
      id: (state) => state.User.userId || '...',
      logged: (state) => state.User.logged,
    }),
    displayMsg() {
      const msgRoutes = ['Home', 'SignIn'];
      return msgRoutes.includes(this.$route.name);
    },
    imgSrc() {
      let icon = '';
      switch (this.$route.name) {
        case 'Home' || 'SignIn':
          icon = 'hello-2';
          break;
        case 'Settings':
          icon = 'settings';
          break;
        case '404':
          icon = '404';
          break;
        default:
          icon = 'payments';
      }
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`../assets/img/girl-${icon}.png`);
    },
  },
};
</script>

<style lang="scss">
.subheader {
  display: block;

  @include sm {
    display: flex;
    justify-content: space-between;
  }

  &__half {
    flex-grow: 1;
  }

  &__img {
    text-align: right;
    height: 23.5rem;

    @include sm {
      text-align: left;
    }
  }

  &__msg {
    height: 40%;
    vertical-align: super;
  }

  &__girl {
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 4rem;
    margin-bottom: 4rem;
    text-align: left;
  }
}
</style>
