<template>
  <p>SignIn page</p>
</template>

<script>
export default {
  name: 'SignIn',
};
</script>

<style lang="scss"></style>
