<script>
import axios from 'axios';

export default {
  name: 'Infoblock',
  props: {
    text: {
      type: String,
    },
    data: {
      type: String,
    },
    period: {
      type: String,
    },
    payment: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    acc() {
      const user = process.env.NODE_ENV === 'production'
        ? this.$cookies.get('user')
        : JSON.parse(localStorage.getItem('user'));
      const { acc } = user;
      return acc;
    },
  },
  methods: {
    download(e) {
      const itemPeriod = e.target.attributes.period.value;
      const user = process.env.NODE_ENV === 'production'
        ? this.$cookies.get('user')
        : JSON.parse(localStorage.getItem('user'));
      const { acc } = user;
      const headers = user?.token ? { 'x-access-token': user.token, Accept: 'application/octet-stream' } : {};
      try {
        axios
          .get('/api/v1/bill', { headers, params: { acc, itemPeriod }, responseType: 'blob' })
          .then((response) => {
            const blob = new Blob([response.data], {
              type: 'application/pdf',
              encoding: null,
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${acc}_${itemPeriod}.pdf`;
            link.click();
            URL.revokeObjectURL(link.href);
          });
      } catch (err) {
        console.log(err);
        if (err.code === 'ERR_BAD_REQUEST') {
          this.$store.dispatch('User/userLogout');
        }
      }
    },
  },
};
</script>

<template>
  <div class="infoblock">
    <div>{{ text }}</div>
    <div v-if="period" class="infoblock__link" :period="period" @click="download">
      &darr;&nbsp;{{ data }}
    </div>
    <div v-else-if="payment">
      <a
        href="https://online.sberbank.ru/CSAFront/payOrderPaymentLogin.do?ReqId=936087966717&ST=ufs.billing"
        class="infoblock__paymentlink"
        target="_blank"
        >Оплата Сбер</a
      >&nbsp;&nbsp;{{ data }}
    </div>
    <div v-else>{{ data }}</div>
  </div>
</template>

<style lang="scss">
.infoblock {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid rgba(46, 122, 147, 0.4);

  & > div:first-child {
    font-size: 1.6rem;
    padding-bottom: 0.2rem;
  }

  & > div:last-child {
    font-size: 2.4rem;
    font-weight: 600;
    margin-left: 3rem;

    &.infoblock__link {
      font-size: 1.4rem;
      cursor: pointer;
    }
  }

  &__paymentlink {
    cursor: pointer;
    color: $darkgreen-font-color;
    text-decoration: none;
    font-size: 1.8rem;
  }
}
</style>
