<template>
  <Card class="main-card--up" :class="{loading: isSprintLoading}">
    <template #title>История платежей</template>
    <infoblock
      v-for="(item, index) in userPayments"
      :key="index"
      :text="makeText(item)"
      :data="amount(item)"
    />
  </Card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Card from '@/components/Card';
import Infoblock from '@/components/Infoblock';

export default {
  name: 'Payments',
  components: {
    Card,
    Infoblock,
  },
  mounted() {
    if (!Object.keys(this.userPayments).length) this.getUserPayments();
  },
  computed: {
    ...mapState({
      userPayments: (state) => state.User.userPayments,
      userId: (state) => state.User.userId,
      isSprintLoading: (state) => state.User.loading,
    }),
  },
  methods: {
    ...mapActions({
      getUserPayments: 'User/getUserPayments',
    }),
    makeText(item) {
      return `${item?.STAMP} (${item?.PAYTYPE_NAME}, ${item?.KASSA_NAME})`;
    },
    amount(item) {
      return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(
        String(item?.PAY_VAL_RUB),
      );
    },
  },
};
</script>

<style lang="scss"></style>
