<template>
  <div>
    <header class="banner">
      <nav class="navbar">
        <div class="container main-nav">
          <a
            class=""
            href="https://lk.ukpremium.ru/"
            title="Управляющая компания Премиум Красноярск"
          >
            <img
              src="@/assets/img/logo0.png"
              class="logo"
              alt="Управляющая компания Премиум Красноярск"
            />
          </a>
          <div class="main-nav__toggle" @click="toggleMenu">
            <div class="d-flex d-xl-none">
              <div class="navbar-toggler" :class="{ collapsed: !showDropDown }">
                <div class="hamburger"><span></span><span></span><span></span></div>
              </div>
            </div>
          </div>
          <div class="collapse navbar-collapse">
            <ul class="main-nav__nav">
              <li class="nav-item menu-item" v-for="(item, index) in menu" :key="index">
                <a
                  class="nav-link"
                  :href="item.link"
                  :target="item.blank ? '_blank' : '_self'"
                  :rel="item.blank ? 'noopener' : ''"
                  >{{ item.title }}</a
                >
              </li>
            </ul>
            <Button class="btn btn-gradient">Личный кабинет</Button>
          </div>
        </div>
        <div class="dropdown" v-if="showDropDown">
          <ul class="main-nav__nav">
            <li class="nav-item menu-item" v-for="(item, index) in menu" :key="index">
              <a
                class="nav-link"
                :href="item.link"
                :target="item.blank ? '_blank' : '_self'"
                :rel="item.blank ? 'noopener' : ''"
                >{{ item.title }}</a
              >
            </li>
          </ul>
          <!-- <Button class="btn btn-gradient">Личный кабинет</Button> -->
        </div>
      </nav>
    </header>
    <Subheader />
  </div>
</template>

<script>
import Button from '@/components/Button';
import Subheader from '@/components/Subheader';

export default {
  name: 'Header',
  components: {
    Button,
    Subheader,
  },
  data() {
    return {
      showDropDown: false,
      menu: [
        {
          title: 'Новости',
          link: 'https://ukpremium.ru/news/',
          blank: false,
        },
        {
          title: 'Обслуживаемые дома',
          link: 'https://ukpremium.ru/doma/',
          blank: false,
        },
        {
          title: 'Раскрытие информации',
          link: 'https://my.dom.gosuslugi.ru/#!/org-info?index=0&amp;orgRootGuid=e839e759-5660-4155-a908-ed597831c4d8',
          blank: true,
        },
        {
          title: 'Конкурсная документация',
          link: 'https://ukpremium.ru/tender/',
          blank: false,
        },
        {
          title: 'Контакты',
          link: 'https://ukpremium.ru/contacts/',
          blank: false,
        },
      ],
    };
  },
  methods: {
    toggleMenu() {
      this.showDropDown = !this.showDropDown;
    },
  },
};
</script>

<style lang="scss">
.banner {
  background: $header-bg;
  box-shadow: $header-shadow;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow: 0 7px 12px rgba(0, 0, 0, 0.15);
  }

  .logo {
    height: 5.5rem;
    padding: 0.1rem;
  }

  .navbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;

    @include lg {
      &.navbar-expand-xl .navbar-collapse {
        flex-grow: initial;
      }
    }

    &-collapse {
      display: none;

      @include sm {
        display: flex;
      }
    }

    .main-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__toggle {
        display: block;

        @include sm {
          display: none;
        }
      }

      &__nav {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include sm {
          flex-direction: row;
        }
      }
    }

    .navbar-nav {
      @include media-breakpoint-down(lg) {
        max-width: 30rem;
        margin: 5rem auto;
      }
    }

    .navbar-collapse.show {
      height: calc(100vh - 63px);
    }

    .dropdown {
      display: block;
      margin: 10rem auto;

      @include sm {
        display: none;
      }
    }

    li {
      list-style: none;
      padding: 1.5rem 1rem;
      position: relative;

      @include sm {
        padding: 0 0.2rem;
      }

      a {
        font-size: 2.3rem;
        font-weight: 500;
        color: $main-font-color;
        border: 1px solid transparent;
        padding: 2rem 3rem;
        transition: color 100ms linear;
        text-decoration: none;

        @include sm {
          font-size: 1.3rem;
          text-align: center;
        }
      }

      .nav-link {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
      }

      &.active,
      &:hover {
        &::after {
          position: absolute;
          bottom: 0.2rem;
          left: 1rem;
          right: 1rem;
          content: '';
          height: 3px;
          background: $green-font-color;
        }
      }

      .dropdown-menu {
        background: transparent;

        @include media-breakpoint-up(xl) {
          border: none;
          box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
          width: 250px;

          li {
            padding-left: 0;
            padding-right: 0;

            a.nav-link {
              color: $main-font-color;
              border-radius: 0;
              border: none;
              padding-left: 0.8rem;
              padding-right: 0.8rem;

              &:hover {
                color: $darkgreen-font-color;
              }
            }
          }
        }
      }
    }
  }

  .navbar-toggler {
    padding: 0;

    &:focus {
      outline: none;
    }

    .hamburger {
      width: 30px;
      height: 25px;
      position: relative;
      margin: 0;
      transform: rotate(0deg);
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        background: $main-font-color;

        &:nth-child(1) {
          top: 11px;
          transform: rotate(135deg);
        }

        &:nth-child(2) {
          top: 10px;
          left: -60px;
          opacity: 0;
        }

        &:nth-child(3) {
          top: 11px;
          transform: rotate(-135deg);
        }
      }
    }

    &.collapsed {
      .hamburger span {
        transform: rotate(0deg);
        left: 0;
        opacity: 1;

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2) {
          top: 10px;
        }

        &:nth-child(3) {
          top: 20px;
        }
      }
    }
  }
}
</style>
