<template>
  <div class="main-card">
    <div class="main-card__title">
      <slot name="title"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Card',
};
</script>

<style lang="scss">
.main-card {
  border-radius: 2.5rem;
  box-shadow: $modal-shadow;
  background: #fff;
  padding: 3rem;
  color: $main-font-color;
  position: relative;

  &__title {
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 4rem;
    color: $darkgreen-font-color;

    .subline {
      font-size: 1.5rem;
      font-weight: 400;
      color: $main-font-color;
    }
  }

  &--up {
    margin-top: -10rem;
  }

  &.loading {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 2.5rem;
      background: url('../assets/img/loader.svg') center no-repeat, #fff;
      opacity: 0.8;
      content: "";
    }
  }
}
</style>
