<script>
import { mapActions, mapState } from 'vuex';
import Button from '@/components/Button';
import Card from '@/components/Card';
import InputComponent from '@/components/InputComponent';

export default {
  name: 'Login',
  components: {
    Card,
    Button,
    InputComponent,
  },
  data() {
    return {
      acc: '',
      password: '',
    };
  },
  computed: {
    ...mapState({
      register: (state) => state.User.register,
      error: (state) => state.User.error,
    }),
  },
  methods: {
    ...mapActions({
      login: 'User/userLogin',
    }),
    signin() {
      this.$store.commit('User/setRegister', !this.register);
    },
    releaseError() {
      this.$store.commit('User/setError', false);
    },
  },
};
</script>

<template>
  <Card class="main-card--up" v-if="!register">
    <template v-slot:title>Войдите в личный кабинет</template>
    <div class="main-form login__form">
      <InputComponent
        placeholder="Номер лицевого счёта"
        v-model="acc"
        :required="true"
        :error="error"
        @input="releaseError"
      />
      <InputComponent
        placeholder="Пароль"
        v-model="password"
        :required="true"
        :error="error"
        type="password"
        @input="releaseError"
      />
      <div v-if="error" class="alert__error">Неправильное имя пользователя или пароль</div>
    </div>
    <Button @click="login({ acc, password })" class="btn-primary">Войти</Button>
    <!--     <div class="login__link">
      <a href="#" @click="signin">Не зарегистрированы?</a>
    </div> -->
  </Card>
  <Card class="main-card--up" v-else>
    <template v-slot:title>Register page</template>
    <Button @click="login">Register</Button>
    <!--     <div class="login__link">
      <a href="#" @click="signin">Уже зарегистрированы?</a>
    </div> -->
  </Card>
</template>

<style lang="scss">
.login {
  &__link {
    margin-top: 3rem;
  }

  &__form {
    max-width: 40rem;
    margin-bottom: 2rem;
  }
}
.alert__error {
  font-size: 1.3rem;
  font-weight: 500;
  color: #ff4900;
}
</style>
