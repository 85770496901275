<template>
  <footer>© ООО «ПРЕМИУМ» 2022&nbsp;&ndash;&nbsp;{{ year }}</footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss">
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: $footer-bg;
  text-align: center;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  height: 7rem;
}
</style>
