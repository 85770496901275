import { createApp } from 'vue';
import VueCookies from 'vue-cookies';
import App from './App';
import router from './router';
import store from './store';
import './assets/styles/index.scss';

const app = createApp(App);
app.config.globalProperties.$cookies = VueCookies;
app.use(store);
app.use(router);
app.use(VueCookies, { expire: '15d', path: '', domain: 'ukpremium.ru' });
app.mount('#app');
