<template>
  <Card class="main-card--up">
    <template #title>Квитанции</template>
    <infoblock
      v-for="(item, index) in periods"
      :key="index"
      :text="item.PERIOD_NAME"
      :period="String(item.PERIOD_ID)"
      data="PDF"
    />
  </Card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Card from '@/components/Card';
import Infoblock from '@/components/Infoblock';

export default {
  name: 'Receipts',
  components: {
    Card,
    Infoblock,
  },
  mounted() {
    if (!Object.keys(this.periods).length) this.getPeriods();
  },
  computed: {
    ...mapState({
      periods: (state) => state.User.periods,
      userId: (state) => state.User.userId,
    }),
  },
  methods: {
    ...mapActions({
      getPeriods: 'User/getPeriods',
    }),
  },
};
</script>

<style lang="scss"></style>
