<template>
  <Card class="main-card--up">
    <template #title>Оплата прошла успешно</template>
    <p>Перерасчет в системе Спринт ЖКХ происходит в течение нескольких рабочих дней.</p>
    <p v-if="payment">
      Платеж обработан, статус: "{{ payment.errorMessage }}" (код "{{ payment.orderStatus }}")
    </p>
    <br>
    <Button to="/" class="btn__logout">На главную</Button>
  </Card>
</template>

<script>
import Card from '@/components/Card';
import Button from '@/components/Button';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Success',
  components: { Card, Button },
  data() {
    return {
      orderId: '',
    };
  },
  async mounted() {
    this.orderId = this.$route.query?.orderId;
    if (this.orderId) {
      await this.checkPayment({ orderId: this.orderId });
    }
    if (this.payment) {
      this.flushLink();
      await this.getUserData();
    }
  },
  computed: {
    ...mapState({
      payment: (state) => state.Sber.paymentState,
    }),
  },
  methods: {
    ...mapActions({
      getUserData: 'User/getUserData',
      checkPayment: 'Sber/checkPayment',
      flushLink: 'Sber/flushLink',
    }),
  },
};
</script>

<style lang="scss"></style>
