<template>
  <Card class="main-card--up">
    <template #title>Ошибка при проведении оплаты</template>
    <p>Код ошибки: {{ payment.orderStatus }}</p>
    <br>
    <Button to="/" class="btn__logout">На главную</Button>
  </Card>
</template>

<script>
import Card from '@/components/Card';
import Button from '@/components/Button';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Failed',
  components: { Card, Button },
  data() {
    return {
      orderId: '',
    };
  },
  async mounted() {
    this.orderId = this.$route.query?.orderId;
    if (this.orderId) {
      await this.checkPayment({ orderId: this.orderId });
    }
    if (this.payment) {
      this.flushLink();
    }
  },
  computed: {
    ...mapState({
      payment: (state) => state.Sber.paymentState,
    }),
  },
  methods: {
    ...mapActions({
      checkPayment: 'Sber/checkPayment',
      flushLink: 'Sber/flushLink',
    }),
  },
};
</script>

<style lang="scss"></style>
