<template>
  <div class="nav">
    <div>
      <router-link to="/"
        ><img src="../../assets/img/ic-face.svg" alt="profile" />Мой профиль</router-link
      >
    </div>
    <div>
      <router-link to="/counters"
        ><img src="../../assets/img/ic-water.svg" alt="water" />Начисления</router-link
      >
    </div>
    <div>
      <router-link to="/receipts"
        ><img src="../../assets/img/ic-doc.svg" alt="doc" />Квитанции</router-link
      >
    </div>
    <div>
      <router-link to="/payments"
        ><img src="../../assets/img/ic-time.svg" alt="time" />История платежей</router-link
      >
    </div>
    <div>
      <router-link to="/settings"
        ><img src="../../assets/img/ic-settings.svg" alt="time" />Настройки</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'Nav',
};
</script>

<style lang="scss">
.nav {
  > div {
    margin-bottom: 1.5rem;
  }

  a {
    color: $main-font-color;
    text-decoration: none;
    position: relative;

    img {
      margin-right: 1.6rem;
      width: 1.4rem;
    }

    &.router-link-exact-active {
      font-weight: bold;
    }

    &.router-link-exact-active,
    &:hover {
      &::after {
        position: absolute;
        bottom: -0.3rem;
        left: 3rem;
        right: 0;
        content: '';
        height: 3px;
        background: $green-font-color;
      }
    }
  }
}
</style>
