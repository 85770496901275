<template>
  <Card class="main-card--up" :class="{loading: isSprintLoading}">
    <template #title>Начисления<div class="subline">{{ periodFormat }}</div></template>
    <infoblock
      v-for="(item, index) in userCounters"
      :key="index"
      :text="makeText(item)"
      :data="amount(item.ALL_VAL2)"
    />
  </Card>
</template>

<script>
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/ru';
import { mapActions, mapState } from 'vuex';
import Card from '@/components/Card';
import Infoblock from '@/components/Infoblock';

dayjs.extend(customParseFormat);

export default {
  name: 'Counters',
  components: {
    Card,
    Infoblock,
  },
  mounted() {
    if (!Object.keys(this.userCounters).length) this.getUserCounters();
  },
  computed: {
    ...mapState({
      userCounters: (state) => state.User.userCounters,
      userId: (state) => state.User.userId,
      period: (state) => state.User.currentPeriod,
      isSprintLoading: (state) => state.User.loading,
    }),
    periodFormat() {
      return this.period ? dayjs(String(this.period), 'YYYYMM').locale('ru').format('MMMM YYYY') : '';
    },
  },
  methods: {
    ...mapActions({
      getUserCounters: 'User/getUserCounters',
    }),
    makeText(item) {
      const correction = item.CORRECT_VAL || item.ADJ_VAL ? `(кор: ${this.amount(item.DET_VAL)}, ${this.amount(item.CORRECT_VAL)}, +${this.amount(item.ADJ_VAL)})` : '';
      return `${item.SERVICE_NAME} ${correction}`;
    },
    amount(item) {
      return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(String(item));
    },

  },
};
</script>

<style lang="scss"></style>
