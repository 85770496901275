<script>
export default {
  name: 'Input',
  inheritAttrs: false,
  props: {
    modelValue: {
      default: '',
    },
    error: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
    value: {
      type: [Number, String],
    },
  },
};
</script>

<template>
  <div class="input-container">
    <span class="label" v-if="$slots.label">
      <slot name="label"></slot>
    </span>
    <input
      v-bind="$attrs"
      :value="modelValue"
      :required="required"
      :class="{'input--error': error}"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>

<style lang="scss">
.main-form {
  text-align: center;

  .input-container {
  }
  .input--error {

  }

  input,
  textarea {
    background: #eaeaea;
    border-radius: 3px;
    border: 1px solid transparent;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    padding: 1rem 1.5rem;
    font-size: 1.4rem;
    position: relative;

    &.input--error {
      border: 1px solid #ff4900;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #949494;
      font-size: 1.4rem;
      font-weight: 500;
    }

    &[type='checkbox'] {
      width: fit-content;
      margin-right: 1rem;
    }

    &:required {
      &::after {
        position: absolute;
        right: 0;
        top: 0;
        content: '*';
        color: $darkgreen-font-color;
      }
    }

    &:required::before {
      content: '*';
      color: red;
    }
  }

  label,
  .label {
    font-size: 1.3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: $grey-font-color;
  }

  &__subline {
    font-weight: 500;
    color: $main-font-color;
    font-size: 1.3rem;
    margin-bottom: 2rem;
    text-align: left;
    text-decoration: underline;
  }

  &__footnote {
    font-weight: 500;
    color: lighten($darkgreen-font-color, 10%);
    font-size: 1.2rem;
    margin-bottom: 2rem;
    text-align: left;
  }

  .btn {
    min-width: 18rem;
  }
}
</style>
