import { createRouter, createWebHistory } from 'vue-router';
import SignIn from '@/views/SignIn';
import Counters from '@/views/Counters';
import Receipts from '@/views/Receipts';
import Payments from '@/views/Payments';
import Settings from '@/views/Settings';
import Home from '@/views/Index';
import Success from '@/views/Success';
import Failed from '@/views/Failed';
import NotFound from '@/views/404';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn,
  },
  {
    path: '/counters',
    name: 'Counters',
    component: Counters,
  },
  {
    path: '/receipts',
    name: 'Receipts',
    component: Receipts,
  },
  {
    path: '/payments',
    name: 'Payments',
    component: Payments,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn,
  },
  {
    path: '/payment-success',
    name: 'success',
    component: Success,
  },
  {
    path: '/payment-failed',
    name: 'failed',
    component: Failed,
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
