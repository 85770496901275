<template>
  <Card class="main-card--up">
    <template #title>Настройки</template>
    <div v-if="email && !showForm">
      <Infoblock
        text="Электронная почта"
        :data="email"
      />
      <Button
        @click="toggleForm"
        class="btn__logout">
        Поменять email
      </Button>
    </div>
    <!-- <div
      class="infoblock infoblock__settings"
      v-else >
      <div class="main-form">
        <InputComponent
          v-model="newEmail"
          :value="email"
          type="email"/>
      </div>
      <div>
        <div>
          <Button
            @click="setEmail"
            :disabled="!newEmail"
            class="btn-primary">
            Задать email
          </Button>
        </div>
      </div>
    </div> -->
    <Button @click="logout" class="btn__logout">Выйти</Button>
  </Card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Card from '@/components/Card';
import Infoblock from '@/components/Infoblock';
// import InputComponent from '@/components/InputComponent';
import Button from '@/components/Button';

export default {
  name: 'Settings',
  components: {
    Card,
    Infoblock,
    // InputComponent,
    Button,
  },
  data: () => ({
    newEmail: '',
    showForm: false,
  }),
  computed: {
    ...mapState({
      userParams: (state) => state.User.userParams,
      userId: (state) => state.User.userId,
    }),
    email() {
      return this.userParams?.email;
    },
  },
  methods: {
    ...mapActions({
      logout: 'User/userLogout',
      setEmail: 'User/setEmail',
    }),
    toggleForm() {
      this.showForm = !this.showForm;
    },
  },
};
</script>

<style lang="scss">
.btn__logout {
  margin-top: 4rem;
}

.infoblock__settings > div:last-child {
  align-self: center;

  .btn-gradient {
    padding: 1rem 2rem;
  }
}
</style>
